<template>
  <div class="homework">
    <div class="box">
      <div class="search-box">
        <el-row :gutter="20" style="margin-left: -37px">
          <el-col :span="8">
            <!--                        <el-date-picker-->
            <!--                                v-model="selectDate"-->
            <!--                                format="yyyy-MM-dd"-->
            <!--                                value-format="yyyy-MM-dd 00:00:00"-->
            <!--                                type="daterange"-->
            <!--                                @change="search"-->
            <!--                                clearable-->
            <!--                                range-separator="-"-->
            <!--                                start-placeholder="开始日期"-->
            <!--                                end-placeholder="结束日期">-->
            <!--                        </el-date-picker>-->
          </el-col>
          <!--                    <el-col :span="4" style="margin-top: 3px">-->
          <!--                        <el-button type="primary" size="small" :loading="loading" style="width: 70px;background: #39A5EF;" @click="search">搜 索</el-button>-->
          <!--                        <el-button type="primary" size="small" style="width: 70px;background: #39A5EF;" @click="reset">重 置</el-button>-->
          <!--                    </el-col>-->
        </el-row>
      </div>
      <div v-if="total!=0">
        <div style="height: 560px">
          <div v-loading="loading" class="list-box" element-loading-background="rgba(0, 0, 0, 0)">
            <div v-for="(item,index) in list" :key="index" class="homework-item" @click="goDetail(item)">
              <div class="top" style="overflow: hidden">
                <div class="top-l">
                  <el-tooltip class="item" effect="dark" :content="item.name" placement="top" :disabled="!showTip">
                    <div style="font-weight: 600;font-size: 16px" @mouseover="textRange">
                      {{ item.name }}
                    </div>
                  </el-tooltip>
                  <div style="font-size: 14px;color: #b7b7b7;padding: 10px 0">
                    {{ item.createTime }}
                  </div>
                </div>
                <div class="top-r">
                  <img
                    src="../../assets/images/homework1.png"
                    style="background-color: #ddebff;-webkit-border-radius: 9px;"
                    alt=""
                  >
                </div>
                <!--                        <span style="float: left"><i class="el-icon-document" style="padding-right: 5px"></i>作业信息</span>-->
                <!--                        <span style="float: right">{{item.createTime}}</span>-->
              </div>
              <div class="bottom">
                <!--                  <div class="homeName" style="text-align: left">{{item.name}}</div>-->
                <div v-if="item.remark==null||item.remark==''" class="title">暂无作业描述~</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.remark"
                  placement="top"
                  :disabled="!showContentTip"
                >
                  <div
                    v-if="item.remark!==null&&item.remark!==''"
                    class="title"
                    style="color: black;font-size: 14px;word-break: break-word"
                    @mouseover="textContentRange"
                  >
                    {{ item.remark }}
                  </div>
                </el-tooltip>
                <!--                        <div class="content">{{item.remark}}</div>-->
              </div>
            </div>
          </div>
        </div>

        <div class="pageBox">
          <div style="text-align: right;" class="page">
            <el-pagination
              background
              :current-page="currentPage"
              :page-size="pageSize"
              layout=" prev, pager, next, jumper"
              :total="total"
              @current-change="currentChange"
            />
          </div>
        </div>
      </div>
      <el-empty
        v-else
        style="min-height: 500px"
        description="暂无数据"
        :image="require('@/assets/images/empty.png')"
      />
    </div>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import { getHomeworkList } from '../../api/course'

export default {
  name: 'Homework',
  components: { indexBottom },
  data() {
    return {
      selectDate: [],
      list: [],
      loading: true,
      currentPage: 1,
      pageSize: 12,
      total: 0,
      showTip: false,
      showContentTip: false
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    onLoad(data) {
      const obj = {
        studentId: this.$store.state.user.userInfo.id,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        ...data
      }
      getHomeworkList(obj).then(res => {
        if (res.code === 200) {
          this.loading = false
          this.list = res.rows
          this.total = res.total
        }
      })
    },
    currentChange(val) {
      this.currentPage = val
      console.log(val)
      this.onLoad()
    },
    search() {
      this.currentPage = 1
      if (JSON.stringify(this.selectDate) != 'null') {
        const obj = {
          startTime: this.selectDate[0],
          endTime: this.selectDate[1]
        }
        this.onLoad(obj)
      } else {
        this.onLoad()
      }
    },
    goDetail(data) {
      //  判断状态是答题还是教师已经批阅
      if (data.isPublished === 0 || data.isPublished === null) {
        this.$router.push({ params: data, name: 'homeworkDetail', query: { id: data.taskLogId }})
      } else {
        this.$router.push({ params: data, name: 'homeworkSubmitted', query: { id: data.taskLogId }})
      }
    },
    reset() {
      this.selectDate = []
      const obj = {
        pageSize: this.pageSize,
        pageNum: 1
      }
      this.onLoad(obj)
    },
    textRange(el) {
      console.log(el)
      const textContent = el.target
      const targetW = textContent.getBoundingClientRect().width
      const range = document.createRange()
      range.setStart(textContent, 0)
      range.setEnd(textContent, textContent.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      console.log(rangeWidth > targetW)
      this.showTip = rangeWidth > targetW
    },
    textContentRange(el) {
      console.log(el)
      const textContent = el.target
      const targetW = textContent.getBoundingClientRect().width
      const range = document.createRange()
      range.setStart(textContent, 0)
      range.setEnd(textContent, textContent.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      console.log(rangeWidth > targetW)
      this.showContentTip = rangeWidth > targetW
    }
  }
}
</script>

<style scoped lang="less">
.homework {

  .box {
    width: 1200px;
    margin: 0 auto;
    //padding: 0 10px;
    min-height: 650px;
  }

  .search-box {
    //margin-top: 10px;
    ::v-deep .el-range-editor {
      background: #EDF1F7;

      .el-range-input {
        background: #EDF1F7;
      }
    }
  }

  .list-box {
    display: flex;
    flex-wrap: wrap;
    //background-color: #FFFFFF;
    //padding: 20px 0;
    .homework-item:hover {
      position: relative;
      bottom: 5px;
      //left: 3px;
    }

    .homework-item {
      background: white;
      width: 280px;
      height: 172px;
      border-radius: 6px;
      margin: 0px 10px 14px 0;
      cursor: pointer;
      //box-sizing: border-box;
      //padding: 0px 12px 14px;
      border: 0.5px solid #e8e8e8;

      .top {
        border-radius: 6px 6px 0 0;
        height: 90px;
        //background: #39A5EF;
        margin: 0px 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #E7ECF2;

        .top-l {
          width: 70%;
          text-align: left;
          float: left;
          padding: 10px 10px 0 10px;

          div {
            margin: 5px 0;
            min-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .top-r {
          width: 30%;
          overflow: hidden;
          float: right;
          padding: 10px 0;

        }

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 48px;
        }
      }

      .bottom {
        padding: 0 10px;

        .homeName {
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          margin: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          color: #999999;
          text-align: left;
          margin: 10px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        .content {
          text-align: left;
          margin: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }

    }
  }

  .pageBox {
    width: 1200px;
    margin-top: 20px;
    height: 40px;
    position: relative;

    .page {
      position: absolute;
      right: 25px;
      bottom: 10px;
      width: auto;
    }

    //::v-deep.el-pagination{
    //    .el-pager{
    //        .number{
    //            color: #999999;
    //            background: #EDF1F7;
    //        }
    //        .active{
    //            color: #39A5EF;
    //        }
    //        .more{
    //            color: #999999;
    //            background: #EDF1F7;
    //        }
    //    }
    //    .btn-prev,.btn-next{
    //        background: #EDF1F7 ;
    //    }
    //}
  }
}
</style>
